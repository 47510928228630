'use client'
import React, { useEffect } from 'react'
import NavigationalHeader from '../NavigationalHeader'
import Footer from '../Footer'
import { usePathname } from 'next/navigation'

const NavigableLayout = ({
  dictionary,
  children,
}: {
  dictionary: { [key: string]: string }
  children: React.ReactNode
}) => {
  const pathname = usePathname()

  //blocks scroll restoration
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <div
      className={`flex flex-col items-center min-h-screen h-full text-enpalGray-600 ${
        pathname == '/' || pathname == '/installation-overview'
          ? 'bg-enpalGray-50'
          : 'bg-container-light md:bg-enpalGray-50'
      } `}
    >
      <div className="w-full">
        <NavigationalHeader dictionary={dictionary} />
      </div>
      <div className="flex-1 flex-col md:px-36 px-3 w-full">
        <div className="flex-1 flex-col">
          <div className="max-w-[772px] relative mx-auto">{children}</div>
        </div>
      </div>
      <Footer dictionary={dictionary} />
    </div>
  )
}

export default NavigableLayout
