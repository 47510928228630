'use client'
import { usePathname, useSearchParams, useRouter } from 'next/navigation'
import EnpalWhiteLogo from 'assets/icons/enpal-logo-white.svg'
import cn from 'classnames'
import LogoutIcon from 'assets/icons/v2/logout-icon.svg'
import { signOut } from 'next-auth/react'
import {
  FunctionComponent,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react'
import LANGUAGE_KEYS from 'utils/languageKeys'
import Image from 'next/image'
import IpoImage from 'assets/images/ipo-header-img.png'
import { pushToHistorySelector, clearHistorySelector } from 'store/historyStore'
import { useStore } from 'store/store'

/** The name of the query parameter to indicate that the user is an app user. */
const appUserQueryParamName = 'app-user'

interface INavigationHeaderProps {
  dictionary: { [key: string]: string }
}

/**
 * Header component to provide navigation between pages.
 *
 * @returns {ReactElement} The rendered navigation header.
 */
const NavigationalHeader: FunctionComponent<INavigationHeaderProps> = (
  props
): ReactElement => {
  const router = useRouter()
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const pushToHistory = useStore(pushToHistorySelector)
  const clearHistory = useStore(clearHistorySelector)
  useEffect(() => {
    pathname && pushToHistory(pathname)
  }, [pathname, pushToHistory])

  /** Whether the user is currently at the installation overview page or not. */
  const isInstallationOverviewPage = useMemo(
    () => pathname === '/installation-overview',
    [pathname]
  )
  /** Whether the user is currently at the home page or not. */
  const isHomePage = useMemo(() => pathname === '/', [pathname])

  /** Whether the navigation header shall be displayed customized for an app user or not. */
  const [isAppUser, setIsAppUser] = useState<boolean | null>(null)

  /** Initialize the state from the query parameter. */
  useEffect(() => {
    if (isAppUser !== null) return
    setIsAppUser(searchParams?.get(appUserQueryParamName) === 'true')
  }, [isAppUser, searchParams])

  const logout = () => {
    clearHistory()
    signOut({ callbackUrl: '/' })
  }
  return (
    <div className="w-full">
      <nav
        className="flex w-full items-center justify-center relative bg-enpalGray-600 h-14 py-2 z-0"
        id="portal-navigation"
      >
        <div className="flex w-full max-w-screen-3xl items-center px-5 md:px-14">
          {!isAppUser && (
            <button
              className={`navbar flex items-center justify-center ${
                isHomePage ? '' : 'hidden md:block'
              }`}
              data-testid="logo-redirect"
              onClick={() => router.push('/')}
            >
              <EnpalWhiteLogo
                height="32px"
                aria-label="Enpal Logo"
                role="img"
                alt="Enpal Logo"
                className={cn('md:h-[30px]', 'mr-1')}
              />
            </button>
          )}
          {!isAppUser && (
            <div className="navbar bg-base-100 md:p-3 flex ml-auto items-center hover:underline rounded-full">
              <button onClick={logout} data-testid="logout-button">
                <LogoutIcon className="stroke-2" />
              </button>
            </div>
          )}
        </div>
      </nav>
      {isInstallationOverviewPage && (
        <div className="flex items-center min-h-60 h-60 md:h-72 md:min-h-72 w-full overflow-hidden relative">
          <Image
            src={IpoImage}
            fill
            priority
            alt=""
            className="object-cover bg-dark-blue"
          />
          <div className="left-1/2 absolute flex items-center -translate-x-1/2 pb-9 flex-col text-center w-4/5">
            <div className="text-3xl md:text-5xl font-bold mb-3">
              <span className="text-white">
                {props.dictionary[LANGUAGE_KEYS.IPO_HEADING]}
              </span>
              <span className="text-enpal-yellow">.</span>
            </div>
            <div className="text-enpal-yellow text-base md:text-2xl font-medium">
              {props.dictionary[LANGUAGE_KEYS.IPO_SUBLINE]}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default NavigationalHeader
