'use client'

import Auth from 'components/organisms/Auth'

export default function AuthenticationContext({
  children,
}: {
  children: React.ReactNode
}) {
  return <Auth>{children}</Auth>
}
