'use client'
import { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { GET_CADASTRE_REGISTRANTS } from 'graphql/mutations'
import { GetCadastreRegistrantByGlobalId } from '__generated__/GetCadastreRegistrantByGlobalId'
import { INTERCOM_APP_ID } from 'config/constants'
import { useSession } from 'next-auth/react'
import { useIntercom } from 'hooks/useIntercom'
import { usePathname } from 'next/navigation'
import { createHmac } from 'crypto'

export default function Intercom({ secret }: { secret: string | undefined }) {
  const { data: session } = useSession()
  const { setIsOpen } = useIntercom()
  const pathname = usePathname()

  const { data: registrantsData, loading: loadingRegistrants } =
    useQuery<GetCadastreRegistrantByGlobalId>(GET_CADASTRE_REGISTRANTS, {
      variables: { customerId: session?.user.id as string },
    })

  function createIntercomMetaTag() {
    const meta = document.createElement('span')
    meta.id = 'intercom-viewport-meta'
    document.getElementById('intercom-container')?.appendChild(meta)
  }

  useEffect(
    function update() {
      if (!window.Intercom) return
      window.Intercom('update')
    },
    [pathname]
  )

  useEffect(
    function loadIntercom() {
      if (
        loadingRegistrants ||
        !session?.user.id ||
        !window.Intercom ||
        !secret
      ) {
        return
      }

      const firstname =
        registrantsData?.getCadastreRegistrantByGlobalId?.cadastreRegistrant
          ?.firstName
      const lastName =
        registrantsData?.getCadastreRegistrantByGlobalId?.cadastreRegistrant
          ?.lastName

      window.Intercom('boot', {
        app_id: INTERCOM_APP_ID,
        api_base: 'https://api-iam.eu.intercom.io',
        user_id: session?.user.id as string,
        user_hash: createHmac('sha256', secret as string)
          .update(session?.user.id as string)
          .digest('hex'),
        name: `${firstname} ${lastName}`,
        email:
          registrantsData?.getCadastreRegistrantByGlobalId?.cadastreRegistrant
            ?.email ?? undefined,
      })
      window.Intercom('onHide', function () {
        createIntercomMetaTag()
        setIsOpen(false)
      })
      window.Intercom('onShow', function () {
        setIsOpen(true)
      })
      return () => {
        window.Intercom('shutdown')
      }
    },
    [
      loadingRegistrants,
      session?.user.id,
      registrantsData?.getCadastreRegistrantByGlobalId,
      setIsOpen,
      secret,
    ]
  )

  return (
    <div id="intercom-container">
      <span id="intercom-viewport-meta" />
    </div>
  )
}
