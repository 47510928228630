'use client'
import { useMemo } from 'react'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import cn from 'classnames'
import ChevronLeft from 'assets/icons/chevron-left.svg'
import { BACKBUTTON_ROUTES_MAP } from 'app/(navigable)/redirectPaths'
import { isAnyPrevPageSelector } from 'store/historyStore'
import { useStore } from 'store/store'

const BackButton = ({ serverRedirect }) => {
  const pathname = usePathname() ?? '/'
  const searchParams = useSearchParams()
  const router = useRouter()

  const hasPrevPage = useStore(isAnyPrevPageSelector)

  const isInstallationOverviewPage = useMemo(
    () => pathname === '/installation-overview',
    [pathname]
  )

  const redirectPage =
    (searchParams?.size ?? 0) > 0 ? pathname : BACKBUTTON_ROUTES_MAP[pathname]

  const backButtonHandler = () => {
    if (hasPrevPage()) {
      router.back()
    } else {
      serverRedirect(redirectPage)
    }
  }

  return (
    <button
      data-testid="navbar-back-button"
      onClick={() => backButtonHandler()}
      className={cn(
        'navbar bg-base-100 md:p-3 flex items-center justify-center transition ease-out duration-150 bg-none md:hover:bg-container-light hover:transition-none rounded-full',
        {
          'md:hover:text-dark-blue': isInstallationOverviewPage,
        }
      )}
    >
      <ChevronLeft className="w-6" />
    </button>
  )
}

export default BackButton
